const times = {
  '00.00': 'fulfilling every desire, thought with a pure heart',
  '01.01': 'wait for good news from the men game',
  '01.10': 'alas, you have started, it’s not bring the expected result',
  '01.11': 'do not give up now on any Propositions',
  '02.02': 'wait for an invitation to visit or club',
  '02.20': 'suppress irritation, follow the words',
  '02.22': 'you will discover a mystery',
  '03.03': 'knocking at your door love',
  '03.30': 'alas, your sense of change remain unanswered',
  '03.33': 'Meet the happiness and good fortune',
  '04.04': 'look at the situation from the other side',
  '04.40': 'Today is clearly not your day fortune to you is (nice man)',
  '04.44': 'get a dressing down from his superiors',
  '05.05': 'secret enemies plotting against you unfairly',
  '05.50': 'Beware of water and fire',
  '05.55': 'not far off the meeting with a wise man',
  '06.06': 'speedy marriage (marriage)',
  '07.07': 'beware of men in uniform',
  '08.08': 'career took off',
  '09.09': 'guard your purses and handbags',
  '10.01': 'acquaintance with the influential men',
  '10.10': 'it’s time',
  '11.11': 'get dependent on someone (or something)',
  '12.12': 'success on the love front.',
  '12.21': 'meeting with a charming woman',
  '13.13': 'Beware rivals',
  '13.31': 'get what long dreamed',
  '14.14': 'now a ball is ruled by love',
  '14.41': 'get into trouble',
  '15.15': 'follow the advice of a wise man',
  '15.51': 'get ready for the rapid, but short novel',
  '16.16': 'be careful on the road',
  '17.17': 'Beware of street hoodlums',
  '18.18': 'be careful on the road',
  '19.19': 'success in business',
  '20.02': 'quarrel with another person.',
  '20.20': 'scandal in the family',
  '21.12': 'the birth of a child or a new project',
  '21.21': 'a whirlwind romance',
  '22.22': 'new acquaintance',
  '23.23': 'a dangerous relationship',
  '23.32': 'problems of health',
}

export default times
import React, { Component } from 'react';
import { doTheMagic, breakDate } from './logic'
import './App.css';
import moment from 'moment'
window.moment = moment

class App extends Component {
  state = {}

  constructor(props) { 
    super(props)
    this.state = this.tick()
    setInterval(() => this.setState(this.tick()), 1000)
  }

  tick = () => {
    const date = moment(getParameterByName('date') || new Date())
    const matches = doTheMagic(date)
    return {
      ...breakDate(date),
      matches
    }
  }

  render() {
    const { seconds, hours, minutes, date, month, year, dateRoot, completeRoot, matches } = this.state
    return (
      <div className="App">
        <header>
          <div className="time">
            <span>{pad2(hours)}</span>
            <span className="sep">:</span>
            <span>{pad2(minutes)}</span>
            <small>
              <span className="sep">.</span>
              <span className="seconds">{pad2(seconds)}</span>
            </small>
          </div>
          <div className="date">
            <span>{date}</span>
            <span className="sep">/</span>
            <span>{month}</span>
            <span className="sep">/</span>
            <span>{year}</span>
          </div>
        </header>
        <hr />
        <h1>Date root: {dateRoot}, Complete root: {completeRoot}</h1>
        { matches && matches.length > 0 && 
          matches.map(({ title, description, link, caption }) => (
            <main>
              <hr />
              <h1>{title}</h1>
              <p>{description}</p>
            </main>
          ))
        }
      </div>
    )
  }
}

function pad2(number) {
   return (number < 10 ? '0' : '') + number
}


function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export default App;

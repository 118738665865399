import _ from 'lodash'
import numerologystars from './numerologystars'

const rules = [
  {
    name: 'sameHourMinutes',
    priority: 1,
    compute: ({ hours, minutes }) => {
      if (hours === minutes) {
        return {
          title: `Hours and minutes are same`,
          description: `Magic number ${hours}`,
        }
      }
    }
  },
  {
    name: 'numerologystars.com - Magic of Clock Time',
    priority: 2,
    compute: ({ hours, minutes }) => {
      const match = numerologystars[`${pad2(hours)}.${pad2(minutes)}`]
      if (match) {
        return {
          title: `Magic of Clock Time`,
          description: match
        }
      }
    }
  },

]


function pad2(number) {
   return (number < 10 ? '0' : '') + number
}


export default rules


import _ from 'lodash'
import rules from './rules'

export function doTheMagic(date) {
  const brokenDate = breakDate(date)
  const matchedRules = _.compact(rules.map(({ compute }) => compute(brokenDate)))
  return matchedRules
}

export function breakDate(date) {  
  const obj = {
    fullDate: date,
    seconds: date.seconds(),
    hours: date.hour(),
    minutes: date.minutes(),
    date: date.date(),
    day: date.day(),
    month: date.month() + 1,
    year: date.year(),
  }
  obj.dateRoot = findRoot(obj.date, obj.day, obj.month)
  obj.completeRoot = findRoot(obj.hours, obj.minutes, obj.date, obj.day, obj.month)
  return obj
}

export function findRoot(...numbers) {
  function sumDigits(num) {
    return num.toString().split('').map(digit => Number(digit)).reduce((memo, digit) => memo += digit, 0)
  }
  let rootNumber = sumDigits(numbers.map(sumDigits).reduce((memo, digit) => memo += digit, 0))
  while (rootNumber > 10) {
    rootNumber = sumDigits(rootNumber)
  }
  return rootNumber
}
